/* eslint-disable react/destructuring-assignment */
import { useQueryLoader, usePreloadedQuery } from 'react-relay';
import {
 startTransition, Suspense, useEffect, useState, useRef, useImperativeHandle,
} from 'react';
import { nanoid } from 'nanoid';

/*
  This is final component
  populata setData and getVariables
  setData will receive loadquery data get by usePreloadedQuery
  on another "loading component" call through a ref (loadedComponentFwdRef)

  loadQuery call through that "loading component" by ref (loadQueryListenerRef)

*/
function LoadedComponent({
  Component,
  loadedComponentFwdRef,
  initOnly,
  loadQuery,
  fetchPolicy,
  variables,
  setFetchPolicy,
  log,
  ...props
}) {
  const [data, setData] = useState({});
  const [isInit, setIsInit] = useState(false);

  useImperativeHandle(loadedComponentFwdRef, () => ({
    setData: (dataValue) => {
      setData(dataValue);
      setIsInit(true);
    },
    getVariables: () => variables,
  }), [data]);

  return (!isInit && !initOnly) || <Component
    {...(props || {})}
    {...{
      data: {
        ...data,
        ...(props?.data || {}),
      },
      loadQuery,
      setFetchPolicy,
    }}
  />;
}

function LoadedHoc({
  queryRef, query, setData, log,
}) {
  const data = usePreloadedQuery(query, queryRef);
  if (log) {
    console.log('LoadedHoc', data);
  }
  useEffect(() => {
    setData(data);
  }, []);
  return null;
}

function LoadQueryListener({
  loadQueryListenerFwdRef, query, setData, log,
}) {
  const decodeQueryLoaderDataRef = useRef();
  const [queryRef, currentLoadQuery] = useQueryLoader(query);
  const [dataLoaded, setDataLoaded] = useState(false);

  useImperativeHandle(loadQueryListenerFwdRef, () => ({
    loadQuery: (variable, fetchPolicy) => {
      setDataLoaded(false);
      currentLoadQuery(variable, fetchPolicy);
    },
  }), [queryRef, currentLoadQuery, dataLoaded]);

  return (
    <Suspense fallback={<div />}>
      {(!queryRef || dataLoaded) || <LoadedHoc
        key={nanoid()}
        {...{
          log,
          queryRef,
          query,
          setData: (dataValue) => {
            setData(dataValue);
            setDataLoaded(true);
          },
        }}
      />
      }
    </Suspense>
  );
}
/*
  initOnly : dont run at the fist time
*/
const loaderHoc = ({
  Component, query, providerVariables, initOnly, fetchPolicy, log,
}) => function (props) {
  const loadQueryListenerRef = useRef();
  const LoadedComponentRef = useRef();
  const [currentfetchPolicy, setFetchPolicy] = useState(fetchPolicy || 'network-only');
  const loadQuery = (loadvariables, loadfetchPolicy) => {
    startTransition(() => {
      loadQueryListenerRef?.current?.loadQuery(loadvariables, loadfetchPolicy || currentfetchPolicy);
    });
  };

  useEffect(() => {
    if (!initOnly) {
      startTransition(() => {
        if (log) {
          console.log('providerVariables?.(props)', providerVariables?.(props), currentfetchPolicy);
        }
        loadQuery(providerVariables?.(props) || {}, { fetchPolicy: currentfetchPolicy });
      });
    }
  }, []);

  return (
    <>
      <LoadQueryListener
        loadQueryListenerFwdRef={loadQueryListenerRef}
        query={query}
        setData={
          (data) => {
            LoadedComponentRef?.current?.setData(data);
          }
        }
        log={log}
      />
      <LoadedComponent {...{
        ...props,
        Component,
        setFetchPolicy,
        variables: providerVariables?.(props) || {},
        loadedComponentFwdRef: LoadedComponentRef,
        initOnly,
        fetchPolicy,
        loadQuery,
        log,
      }}
      />
    </>
  );
};

export default loaderHoc;
